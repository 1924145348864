import {
  useLocation,
  useNavigate,
  Router,
  RouteComponentProps,
} from "@reach/router";

import { graphql } from "gatsby";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Collapsible from "react-collapsible";

import { Product, IngredientGroup } from "../interfaces";
import {
  useIntArraySearchParam,
  useStringArraySearchParam,
  useIntSearchParam,
} from "../hooks";
import Layout from "../components/common/Layout";
import { ProductListPagination } from "../components/products/providers";
import {
  ActiveIngredientSelect,
  InactiveIngredientSelect,
  ProductListItem,
  Navigation,
  SupplementFormCheckboxGroup,
  BrandCheckboxGroup,
} from "../components/products";
import { IngredientGroupProductList, Profile } from "../components/remote";

interface Props {
  skip: number;
  limit: number;
  total: number;
  data: {
    ingredient: IngredientGroup;
    products: {
      totalCount: number;
      edges: {
        node: {
          productId: number;
          brand: string;
          supplementForm: string;
          otherIngredients: string[];
        };
      }[];
    };
  };
  pageContext: {
    id: number;
    formattedPriming: string;
  };
  defaultExcludeActiveIngredients: number[];
  defaultExcludeInactiveIngredients: string[];
}

function ProductListItems(
  props: RouteComponentProps & {
    products: Product[];
    total: number;
  }
) {
  const { products, total } = props;

  return (
    <ProductListPagination total={total} products={products}>
      {({ paginated, nextPage, previousPage }) => {
        if (paginated.length === 0) {
          return (
            <div className="container my-3">
              <p className="has-text-centered">
                You&apos;ve filtered out all of the vitamins. Clear your filter
                to see some thing here.
              </p>
            </div>
          );
        }

        return (
          <div>
            <div className="container my-3">
              <Navigation
                total={total}
                next={nextPage}
                previous={previousPage}
              />
            </div>
            <div className="container">
              {paginated.map((product) => (
                <ProductListItem product={product} key={product.id} />
              ))}
            </div>
            <div className="container my-3">
              <Navigation
                total={total}
                next={nextPage}
                previous={previousPage}
              />
            </div>
          </div>
        );
      }}
    </ProductListPagination>
  );
}

function IngredientGroupDetailsInternal(props: Props) {
  const {
    data: {
      ingredient: { groupId, priming },
      products: { edges: productEdges },
    },
    defaultExcludeActiveIngredients,
    defaultExcludeInactiveIngredients,
  } = props;

  const [excludeActiveIngredients, excludeActiveIngredientsActions] =
    useIntArraySearchParam("-ingredientGroups");
  const [excludeInactiveIngredients, excludeInactiveIngredientsActions] =
    useStringArraySearchParam("-otherIngredients");
  const [filterSupplementForms] = useStringArraySearchParam("supplementForms");
  const [filterBrands] = useStringArraySearchParam("brands");
  const [page] = useIntSearchParam("page");
  const [pageSize] = useIntSearchParam("pageSize");

  const minimalProducts = productEdges.map(({ node }) => node);

  const now = moment();
  const title = `Products for ${priming}`;
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://without.care/ingredient/${priming}`,
    },
    headline: title,
    datePublished: now.format(),
    dateModified: now.format(),
    author: {
      "@type": "Organization",
      name: "Without Care",
    },
    publisher: {
      "@type": "Organization",
      name: "Without Care",
    },
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (
      excludeActiveIngredients.length === 0 &&
      excludeInactiveIngredients.length === 0
    ) {
      params.set(
        "-ingredientGroups",
        (defaultExcludeActiveIngredients ?? []).join(",")
      );
      params.set(
        "-otherIngredients",
        encodeURIComponent(
          (defaultExcludeInactiveIngredients ?? [])
            .map((name) => name)
            .join(",")
        ).toLowerCase()
      );

      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, []);

  return (
    <IngredientGroupProductList
      id={groupId}
      excludeIngredientGroups={excludeActiveIngredients}
      excludeOtherIngredients={excludeInactiveIngredients}
      filterSupplementForms={filterSupplementForms}
      filterBrands={filterBrands}
      page={page ?? 1}
      pageSize={pageSize ?? 30}
    >
      {({ data, error, status }) => {
        if (error) {
          return null;
        }

        if (!data) {
          return (
            <progress
              className="progress is-small is-primary"
              max="100"
              style={{ borderRadius: 0 }}
            >
              15%
            </progress>
          );
        }

        const { total, data: products } = data;

        return (
          <Layout>
            <div className="section">
              <Helmet title={title}>
                <script type="application/ld+json">
                  {JSON.stringify(structuredData, null, 2)}
                </script>
              </Helmet>

              <form className="container">
                <div className="columns">
                  <div className="column is-one-half">
                    <p className="has-text-weight-bold">
                      Exclude Active Ingredients
                    </p>
                    <ActiveIngredientSelect
                      products={products as Product[]}
                      onSelect={excludeActiveIngredientsActions.setValue}
                      onRemove={excludeActiveIngredientsActions.remove}
                      onClear={excludeActiveIngredientsActions.clear}
                    />
                  </div>
                  <div className="column is-one-half">
                    <p className="has-text-weight-bold">
                      Exclude Inactive Ingredients
                    </p>
                    <InactiveIngredientSelect
                      products={products as Product[]}
                      onSelect={excludeInactiveIngredientsActions.setValue}
                      onRemove={excludeInactiveIngredientsActions.remove}
                      onClear={excludeInactiveIngredientsActions.clear}
                    />
                  </div>
                </div>
              </form>

              <form className="container">
                <div className="columns">
                  <div className="column is-one-half">
                    <p className="has-text-weight-bold">Filter Formulations</p>
                    <SupplementFormCheckboxGroup products={minimalProducts} />
                  </div>
                  <div className="column is-one-half">
                    <Collapsible
                      trigger="Filter Brands"
                      triggerTagName="p"
                      triggerClassName="has-text-weight-bold is-clickable arrow-right"
                      triggerOpenedClassName="has-text-weight-bold is-clickable arrow-down"
                      transitionTime={500}
                      easing="ease-out"
                      closedHeight="10.5rem"
                    >
                      <BrandCheckboxGroup products={minimalProducts} />
                    </Collapsible>
                  </div>
                </div>
              </form>

              <div className="container">
                <ProductListItems
                  path=":page"
                  products={products as Product[]}
                  total={total}
                />
              </div>
            </div>
          </Layout>
        );
      }}
    </IngredientGroupProductList>
  );
}

export default function IngredientGroupDetailsTemplate(
  props: Omit<Props, "defaultExcludedActiveIngredients">
) {
  return (
    <Profile>
      {({ data, status }) => {
        if (status === "pending") {
          return (
            <progress
              className="progress is-small is-primary"
              max="100"
              style={{ borderRadius: 0 }}
            >
              15%
            </progress>
          );
        }

        const { excludedActiveIngredients, excludedInactiveIngredients } =
          data ?? {};

        return (
          <Router>
            <IngredientGroupDetailsInternal
              {...props}
              defaultExcludeActiveIngredients={excludedActiveIngredients ?? []}
              defaultExcludeInactiveIngredients={
                excludedInactiveIngredients ?? []
              }
              default
            />
          </Router>
        );
      }}
    </Profile>
  );
}

export const query = graphql`
  query ($id: String!) {
    ingredient: withoutCareIngredientGroup(id: { eq: $id }) {
      groupId
      priming
    }

    products: allWithoutCareProduct(
      filter: { groups: { elemMatch: { id: { eq: $id } } } }
    ) {
      totalCount
      edges {
        node {
          productId
          brand
          supplementForm
          otherIngredients
        }
      }
    }
  }
`;
