import React, { useCallback, useEffect, useMemo } from 'react';

import { useIntSearchParam } from '../../../hooks';
import { Product } from '../../../interfaces';

interface Pagination {
  skip: number;
  limit: number;
  isStart: boolean;
  isEnd: boolean;
  paginated: Product[];
  nextPage: () => void;
  previousPage: () => void;
}

interface Props {
  total: number;
  products: Product[];
  children: (pagination: Pagination) => React.ReactElement;
}

const ProductListPagination = (props: Props) => {
  const { children, products, total } = props;

  const [defaultPage, { setValue: setPage }] = useIntSearchParam('page');
  const [defaultPageSize] = useIntSearchParam('pageSize');

  const { page, skip, limit, paginated, isStart, isEnd } = useMemo(() => {
    const page = defaultPage ?? 1;
    const pageSize = defaultPageSize ?? 30;

    const skip = (page - 1) * pageSize;
    const limit = skip + pageSize;

    const isStart = skip === 0;
    const isEnd = limit >= total;

    const paginated = products.slice(skip, limit);

    return { page, pageSize, skip, limit, paginated, isStart, isEnd };
  }, [defaultPage, defaultPageSize, total, products]);

  const nextPage = useCallback(() => setPage(limit < total ? page + 1 : page), [
    page,
    setPage,
  ]);
  const previousPage = useCallback(() => setPage(page > 1 ? page - 1 : 1), [
    page,
  ]);

  useEffect(() => {
    if (skip >= total && page > 1) {
      setPage(1);
    }

    if (skip < 0) {
      setPage(1);
    }

    if (Number.isNaN(skip)) {
      setPage(1);
    }
  }, [total, skip, setPage]);

  return children({
    skip,
    limit,
    paginated,
    isStart,
    isEnd,
    nextPage,
    previousPage,
  });
};

export default ProductListPagination;
